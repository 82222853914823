import * as Sentry from '@sentry/nextjs';
import environment from '@/config/environment';
import logger from '@/utils/logger';
const PREFIX = 'services: metrics:';
const eventsCount = {};
export default {
  // Track an event
  sendEvent(name, data, config) {
    try {
      // Log event in development
      if (environment.isDevelopment) {
        logger.info(...[`${PREFIX} sendEvent: "${name}"`, Object.keys(data || {}).length > 0 ? data : undefined].filter(Boolean));
      } // Check if the event count exceeds the limit


      if (config !== null && config !== void 0 && config.maxEventsPerSession && eventsCount[name] >= config.maxEventsPerSession) {
        return;
      } // @ts-ignore


      window.gtag('event', name, data || {}); // Increment event count

      eventsCount[name] = (eventsCount[name] ?? 0) + 1;
    } catch (err) {
      const wrappedErr = Error(`${PREFIX}: sendEvent: failed to send metrics event "${name}": ${err}`); // Send error report

      Sentry.captureException(wrappedErr);
    }
  }

};