import { useMemo, useSyncExternalStore } from 'react';
import globalEvents from '@/utils/globalEvents';
import { createWrappedError } from '@/utils/errors';
import memory from '@/services/memory';
const PREFIX = 'common: Editor: hooks: useEditingAreasState:';

const useEditingAreasState = () => {
  // Individual state subscribers
  const activeWidgetId = useSyncExternalStore(subscribe, getWidgetIdSnapshot, getWidgetIdSnapshot);
  const activeSectionId = useSyncExternalStore(subscribe, getSectionIdSnapshot, getSectionIdSnapshot);
  const activeSectionEditingArea = useSyncExternalStore(subscribe, getSectionEditingAreaSnapshot, getSectionEditingAreaSnapshot);
  const activeSectionEditingScope = useSyncExternalStore(subscribe, getSectionEditingScopeSnapshot, getSectionEditingScopeSnapshot);
  const activeEditingAreaMetaStr = useSyncExternalStore(subscribe, getEditingAreaMeta, getEditingAreaMeta);
  const activeClickPositionX = useSyncExternalStore(subscribe, getSectionClickPositionX, getSectionClickPositionX);
  const activeClickPositionY = useSyncExternalStore(subscribe, getSectionClickPositionY, getSectionClickPositionY);
  const hasClickPosition = typeof activeClickPositionX === 'number' && typeof activeClickPositionY === 'number' && activeClickPositionX >= 0 && activeClickPositionY >= 0;
  const activeClickPosition = useMemo(() => ({
    x: activeClickPositionX,
    y: activeClickPositionY
  }), [activeClickPositionX, activeClickPositionY]); // Parsed result of current editing area meta

  const activeEditingAreaMeta = useMemo(() => {
    if (!activeEditingAreaMetaStr) return null;
    let result;

    try {
      result = JSON.parse(activeEditingAreaMetaStr);
    } catch (err) {
      throw createWrappedError(`${PREFIX} editing area meta not parsable`, err);
    }

    return result;
  }, [activeEditingAreaMetaStr]);
  return {
    activeWidgetId,
    activeSectionId,
    activeSectionEditingArea,
    activeSectionEditingScope,
    activeEditingAreaMeta,
    activeClickPosition: hasClickPosition ? activeClickPosition : undefined
  };
}; // Notify state subscribers of state changes
// after the editingarea custom events fired


function subscribe(onChange) {
  const unsubscribeSelect = globalEvents.subscribe('editingareaselect', () => setTimeout(onChange));
  const unsubscribeDeselect = globalEvents.subscribe('editingareadeselect', () => setTimeout(onChange));
  return () => {
    unsubscribeSelect();
    unsubscribeDeselect();
  };
} // Individual state snapshot getters


function getWidgetIdSnapshot() {
  return memory.editorStore.activeWidgetId;
}

function getSectionIdSnapshot() {
  return memory.editorStore.activeSectionId;
}

function getSectionEditingAreaSnapshot() {
  return memory.editorStore.activeSectionEditingArea;
}

function getSectionEditingScopeSnapshot() {
  return memory.editorStore.activeSectionEditingScope;
}

function getEditingAreaMeta() {
  return memory.editorStore.activeEditingAreaMeta;
}

function getSectionClickPositionX() {
  return memory.editorStore.activeClickPosition ? memory.editorStore.activeClickPosition.x : -1;
}

function getSectionClickPositionY() {
  return memory.editorStore.activeClickPosition ? memory.editorStore.activeClickPosition.y : -1;
}

export default useEditingAreasState;