import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { remCalc } from '@/config/design';
import { round } from '@/utils/numbers';
const TOOLTIP_OFFSET = remCalc(16); // Helper to adjust measurements based on zoom level

export const adjustForZoom = (value, zoomLevel) => {
  return round(value * (100 / zoomLevel));
}; // Calculate new dimensions when resizing from a corner handle while keeping the aspect ratio.
// Handles both positive and negative changes based on the drag direction.
// Returns rounded width and height values that meet the minimum dimensions.

export const calculateCornerResize = _ref => {
  let {
    startPos,
    deltaX,
    deltaY,
    position,
    minWidth,
    minHeight,
    zoomLevel = 100
  } = _ref;
  // Adjust the deltas based on zoom level
  const adjustedDeltaX = adjustForZoom(deltaX, zoomLevel);
  const adjustedDeltaY = adjustForZoom(deltaY, zoomLevel);
  const aspectRatio = startPos.width / startPos.height;
  let newWidth, newHeight; // Calculate new width based on drag direction

  if (position.includes('Right')) {
    newWidth = Math.max(minWidth, startPos.width + adjustedDeltaX);
  } else {
    newWidth = Math.max(minWidth, startPos.width - adjustedDeltaX);
  } // Calculate new height based on drag direction


  if (position.includes('bottom')) {
    newHeight = Math.max(minHeight, startPos.height + adjustedDeltaY);
  } else {
    newHeight = Math.max(minHeight, startPos.height - adjustedDeltaY);
  } // Calculate dimensions to maintain aspect ratio


  const heightFromWidth = newWidth / aspectRatio;
  const widthFromHeight = newHeight * aspectRatio; // Choose the smaller dimension to prevent oversizing

  if (widthFromHeight <= newWidth) {
    return {
      width: round(Math.max(minWidth, widthFromHeight)),
      height: round(Math.max(minHeight, newHeight))
    };
  }

  return {
    width: round(Math.max(minWidth, newWidth)),
    height: round(Math.max(minHeight, heightFromWidth))
  };
}; // Calculate new dimensions when resizing from a middle handle.
// Allows for independent width and height adjustments without keeping the aspect ratio.
// Returns rounded width and height values that meet the minimum dimensions.

export const calculateMiddleResize = _ref2 => {
  let {
    dimensions,
    startPos,
    deltaX,
    deltaY,
    position,
    minWidth,
    minHeight,
    zoomLevel = 100
  } = _ref2;
  // Adjust the deltas based on zoom level
  const adjustedDeltaX = adjustForZoom(deltaX, zoomLevel);
  const adjustedDeltaY = adjustForZoom(deltaY, zoomLevel);

  const changed = _objectSpread({}, dimensions); // Update single dimension based on handle position


  switch (position) {
    case 'middleLeft':
      changed.width = Math.max(minWidth, startPos.width - adjustedDeltaX);
      break;

    case 'middleRight':
      changed.width = Math.max(minWidth, startPos.width + adjustedDeltaX);
      break;

    case 'middleTop':
      changed.height = Math.max(minHeight, startPos.height - adjustedDeltaY);
      break;

    case 'middleBottom':
      changed.height = Math.max(minHeight, startPos.height + adjustedDeltaY);
      break;
  }

  return {
    width: round(changed.width),
    height: round(changed.height)
  };
}; // Map tooltip positions based on position prop

export const calculateTooltipPosition = position => {
  switch (position) {
    case 'topLeft':
      return {
        left: TOOLTIP_OFFSET,
        top: TOOLTIP_OFFSET
      };

    case 'topRight':
      return {
        right: TOOLTIP_OFFSET,
        top: TOOLTIP_OFFSET
      };

    case 'bottomLeft':
      return {
        left: TOOLTIP_OFFSET,
        bottom: TOOLTIP_OFFSET
      };

    case 'bottomRight':
      return {
        right: TOOLTIP_OFFSET,
        bottom: TOOLTIP_OFFSET
      };

    case 'middleTop':
      return {
        left: '50%',
        top: TOOLTIP_OFFSET,
        transform: 'translateX(-50%)'
      };

    case 'middleBottom':
      return {
        left: '50%',
        bottom: TOOLTIP_OFFSET,
        transform: 'translateX(-50%)'
      };

    case 'middleLeft':
      return {
        top: '50%',
        left: TOOLTIP_OFFSET,
        transform: 'translateY(-50%)'
      };

    case 'middleRight':
      return {
        top: '50%',
        right: TOOLTIP_OFFSET,
        transform: 'translateY(-50%)'
      };
  }
};