import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { useCallback, useEffect, useRef, useState } from 'react';
import { useClickAway } from 'react-use';
import { adjustForZoom, calculateCornerResize, calculateMiddleResize, calculateTooltipPosition } from './utils';
const CORNER_POSITIONS = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];

const useContainerResize = _ref => {
  let {
    enableResize = false,
    minWidth = 200,
    minHeight = 200,
    zoomLevel = 100,
    updatePreviewResolution,
    onFocusedChange
  } = _ref;
  const {
    0: dimensions,
    1: setDimensions
  } = useState(null);
  const containerRef = useRef(null);
  const resizingRef = useRef({
    active: false,
    position: null
  });
  const startPosRef = useRef({
    x: 0,
    y: 0,
    width: 0,
    height: 0
  });
  const tooltipPosRef = useRef({});
  useClickAway(containerRef, () => {
    typeof onFocusedChange === 'function' && onFocusedChange(false);
  });
  const handleStartResize = useCallback((clientX, clientY, position) => {
    if (!containerRef.current || !enableResize) return;
    const rect = containerRef.current.getBoundingClientRect();
    resizingRef.current = {
      active: true,
      position
    };
    const actualWidth = adjustForZoom(rect.width, zoomLevel);
    const actualHeight = adjustForZoom(rect.height, zoomLevel);
    startPosRef.current = {
      x: clientX,
      y: clientY,
      width: actualWidth,
      height: actualHeight
    };
    tooltipPosRef.current = calculateTooltipPosition(position);
    setDimensions({
      width: actualWidth,
      height: actualHeight
    });
  }, [enableResize, zoomLevel]);
  const handleMouseDown = useCallback((e, position) => {
    e.preventDefault();
    handleStartResize(e.clientX, e.clientY, position);
  }, [handleStartResize]);
  const handleTouchStart = useCallback((e, position) => {
    e.preventDefault();
    const touch = e.touches[0];
    handleStartResize(touch.clientX, touch.clientY, position);
  }, [handleStartResize]); // Process the resize movement and calculate
  // the new dimensions respecting the min width/height.

  const handleMove = useCallback((clientX, clientY) => {
    if (!resizingRef.current.active || !resizingRef.current.position) return;
    const position = resizingRef.current.position;
    const deltaX = clientX - startPosRef.current.x;
    const deltaY = clientY - startPosRef.current.y;
    setDimensions(prev => {
      const isCornerHandle = CORNER_POSITIONS.includes(position);
      const calculationParams = {
        dimensions: prev ?? {
          width: minWidth,
          height: minHeight
        },
        startPos: startPosRef.current,
        deltaX,
        deltaY,
        minWidth,
        minHeight
      };
      const newDimensions = isCornerHandle ? calculateCornerResize(_objectSpread(_objectSpread({}, calculationParams), {}, {
        position,
        zoomLevel
      })) : calculateMiddleResize(_objectSpread(_objectSpread({}, calculationParams), {}, {
        position,
        zoomLevel
      }));
      return newDimensions;
    });
  }, [zoomLevel, minWidth, minHeight]);
  const handleMouseMove = useCallback(e => {
    handleMove(e.clientX, e.clientY);
  }, [handleMove]);
  const handleTouchMove = useCallback(e => {
    const touch = e.touches[0];
    handleMove(touch.clientX, touch.clientY);
  }, [handleMove]);
  const handleResizeEnd = useCallback(() => {
    resizingRef.current = {
      active: false,
      position: null
    };
    tooltipPosRef.current = {};

    if (dimensions) {
      updatePreviewResolution({
        width: dimensions.width,
        height: dimensions.height
      });
    }

    setDimensions(null);
  }, [dimensions, updatePreviewResolution]);
  useEffect(() => {
    if (!enableResize) return; // Mouse events

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleResizeEnd); // Touch events

    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleResizeEnd);
    document.addEventListener('touchcancel', handleResizeEnd);
    return () => {
      // Clean up mouse events
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleResizeEnd); // Clean up touch events

      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleResizeEnd);
      document.removeEventListener('touchcancel', handleResizeEnd);
    };
  }, [handleMouseMove, handleResizeEnd, handleTouchMove, enableResize]);
  return {
    containerRef,
    resizingRef,
    tooltipPosRef,
    dimensions,
    handleMouseDown,
    handleTouchStart
  };
};

export default useContainerResize;