import _defineProperty from "/Users/matt/dev/github.com/industriousapps/excelkits-client/node_modules/.pnpm/next@13.5.7_@babel+core@7.25.2_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.79.3/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import debounce from 'lodash/debounce';
import { useState, useCallback } from 'react';

const useResolutionEditing = _ref => {
  let {
    previewResolution,
    activePreviewGroup,
    previewGroups,
    updatePreviewResolution,
    updateActivePreviewGroup
  } = _ref;
  const {
    0: isCustomizingLayout,
    1: setIsCustomizingLayout
  } = useState((previewResolution === null || previewResolution === void 0 ? void 0 : previewResolution.id) === 'custom'); // Emit selected resolution

  const handlePreviewResolutionChange = useCallback(resolutionId => {
    if (!activePreviewGroup) return;
    const resolution = activePreviewGroup.items.find(_ref2 => {
      let {
        id
      } = _ref2;
      return id === resolutionId;
    });
    if (resolution) updatePreviewResolution({
      resolution
    });
  }, [activePreviewGroup, updatePreviewResolution]); // Update active preview/resolution group

  const handlePreviewGroupChange = useCallback(evt => {
    const {
      value
    } = evt.target;

    if (value === 'customize') {
      setIsCustomizingLayout(true);
      updateActivePreviewGroup(null);
      return;
    }

    const group = previewGroups.find(_ref3 => {
      let {
        id
      } = _ref3;
      return id === value;
    });
    if (!group) return;
    updatePreviewResolution({
      resolution: group.items[0]
    });
    updateActivePreviewGroup(group);
    setIsCustomizingLayout(false);
  }, [previewGroups, setIsCustomizingLayout, updatePreviewResolution, updateActivePreviewGroup]); // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleCustomResolutionChange = useCallback(debounce(_ref4 => {
    let {
      width,
      height
    } = _ref4;
    const updates = {
      width: previewResolution.value[0],
      height: previewResolution.value[1]
    };

    if (width) {
      // Add any valid width
      const widthInt = parseInt(width, 10);
      if (widthInt > 0) updates.width = widthInt;
    }

    if (height) {
      // Add any valid height
      const heightInt = parseInt(height, 10);
      if (heightInt > 0) updates.height = heightInt;
    }

    if ((updates.width || 0) + (updates.height || 0) > 0) {
      updatePreviewResolution(_objectSpread({}, updates));
    }
  }, 350), [updatePreviewResolution, previewResolution.value]);
  return {
    isCustomizingLayout,
    handlePreviewResolutionChange,
    handlePreviewGroupChange,
    handleCustomResolutionChange
  };
};

export default useResolutionEditing;